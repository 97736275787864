import type { OnMovementFnOptions } from '@/Assessment/types'
import Assessment from '@/Assessment/index'
import Main from '@/layout/Main'
import assessment from '../symptom-checker/assessment.js'
import { hasAncestorMatching } from '#/dom/traversal'
import { Icon } from '@/common/Icon'
import Image from '@/common/Image'
import useDevFeature from '@/developer/hooks/useDevFeature'
import useData from '#/hooks/useData'
import { useFlag } from '@/developer/FlagProvider'

const Tagline = styled.div(`
  text-4xl
  tracking-tight
  text-balance
  font-semibold
  font-display
  text-center
  md:text-5xl
  lg:text-6xl
`)


const Description = styled.p(`
  mt-4
  text-black/80
  text-center
  text-balance
  px-[3px]
  md:text-lg
  lg:text-xl
`)

const ConditionLink = styled.a(`
  bg-blue-50
  text-sky-700
  rounded-full
  px-3
  py-1
  font-normal
  hover:bg-blue-100
  hover:text-sky-800
  text-center
  select-none
  text-sm
  md:text-base
  motion-safe:transition-all
  motion-safe:duration-50
  forced-colors:border-1
`)

const ConditionArticles = styled.div(`
  flex
  max-w-full
  min-w-0
  gap-4
  flex-wrap
`)

const ConditionArticleLink = styled.a(`
  flex
  flex-col
  items-center
  flex-1
  basis-1
  min-w-64
`)

const ConditionAssessments = styled.div(`
  flex
  flex-col
  max-w-full
  min-w-0
  gap-6
`)

const ConditionAssessmentLink = styled.a(`
  flex
  flex-col
  items-center
  gap-4
  sm:flex-row
`)


const shouldLoadFromStore = () => globalThis.location?.pathname !== '/'

export default function HomePage() {
  const flag = useFlag('demo:healthline')
  const data = useData<Dx.Api.Home.Out>()
  const ref = useRef<typeof Assessment>()

  useDevFeature('symptoms')

  useEffect(() => {
    const reset = () => ref.current?.reset()

    const handler = (event: MouseEvent) => {
      const { target } = event

      if (ref.current && target instanceof HTMLElement && hasAncestorMatching(target, '#home')) {
        event.preventDefault()
        reset()
      }
    }

    window.addEventListener('popstate', reset)
    document.addEventListener('click', handler)

    return () => {
      document.removeEventListener('click', handler)
      window.removeEventListener('popstate', reset)
    }
  }, [])


  const onMovement = useCallback(({ stack }: OnMovementFnOptions) => {
    if (stack.length > 1) {
      window.history.pushState(null, '', '/symptom-checker')
    }
  }, [])

  const pages = data.groupBy('slot')

  return (
    <Main>
      <Assessment
        assessment={assessment}
        group='symptoms'
        onMovement={onMovement}
        ref={ref}
        shouldLoadFromStore={shouldLoadFromStore}
        after={<>
          <div className='mt-12 border-t border-slate-200 pt-12'>
            <div className='flex items-center mb-4'>
              <h2 className='text-3xl font-bold'>Conditions</h2>
              <div className='flex-1' />
              <a
                className='flex items-center'
                href='/health-conditions'>
                <span className='hidden 2xs:inline-block'>Explore more</span>
                <span className='hidden sm:inline'>{'\u00A0'}health conditions</span><Icon name='arrow-right' className='text-blue-500 ml-2' />
              </a>
            </div>

            <div className='flex items-center flex-wrap gap-2 mb-6'>
              {pages.conditionSecondary.map(c =>
                <ConditionLink
                  key={c.path}
                  href={c.path}>
                  {c.title}
                </ConditionLink>
              )}
            </div>

            <ConditionArticles>
              {pages.conditionFeatured.map(c =>
                <ConditionArticle
                  key={c.path}
                  image={c.image!}
                  name={c.title}
                  path={c.path}
                  snippet={c.summary!} />
              )}
            </ConditionArticles>
          </div>



          <div className='my-12 border-t border-slate-200 pt-12'>
            <div className='flex items-center mb-4'>
              <h2 className='text-3xl font-bold'>Assessments</h2>
              <div className='flex-1' />
              <a
                className='flex items-center'
                href='/health-conditions/assessments'>
                <span className='hidden 2xs:inline-block'>Find more</span>
                <span className='hidden sm:inline-block'>{'\u00A0'}assessments</span> <Icon name='arrow-right' className='text-blue-500 ml-2' />
              </a>
            </div>

            <div className='flex items-center flex-wrap gap-2 mb-6'>
              {pages.assessmentSecondary.map(a =>
                <ConditionLink
                  key={a.path}
                  href={a.path}>
                  {a.title}
                </ConditionLink>
              )}
            </div>

            <ConditionAssessments>
              {pages.assessmentFeatured.map(a =>
                <ConditionAssessment
                  key={a.path}
                  image={a.image!}
                  name={a.title}
                  path={a.path}
                  snippet={a.summary!} />
              )}
            </ConditionAssessments>
          </div>
        </>}>
        <Tagline>
          {flag ?
            <>Medical information and health advice you can <span className='text-rose-500'>trust</span>.</> :
            <>Better understand<br />your <span className='text-rose-500'>health</span></>}
        </Tagline>

        <Description>
          Explore your symptoms, find treatments and medications, and get ready for doctor visits.
        </Description>
      </Assessment>
    </Main>
  )
}

type ConditionArticleProps = {
  image: NonNullable<Dx.Api.Home.Out[number]['image']>
  name: string
  snippet: string
  path: string
}

function ConditionArticle(props: ConditionArticleProps) {
  const {
    name,
    snippet,
    path,
    image: {
      externalId,
      description,
    },
  } = props

  return (
    <ConditionArticleLink href={path}>
      <Image
        alt={description}
        src={externalId}
        sizes={{ sm: 330, md: 390 }}
        className='shrink rounded-xl mb-3 aspect-[auto_7/4] w-full' />

      <div className='grow w-fit'>
        <h2 className='text-xl font-semibold mb-1'>{name}</h2>
        <p>{snippet}</p>
      </div>
    </ConditionArticleLink>
  )
}



type ConditionAssessmentProps = {
  name: string
  snippet: string
  path: string
  image: NonNullable<Dx.Api.Home.Out[number]['image']>
}

function ConditionAssessment(props: ConditionAssessmentProps) {
  const {
    name,
    snippet,
    path,
    image: {
      externalId,
      description,
    },
  } = props

  return (
    <ConditionAssessmentLink href={path}>
      {externalId && <Image
        src={externalId}
        alt={description}
        sizes={{ sm: 192 }}
        className='shrink rounded-xl sm:max-w-48 aspect-[auto_7/4] w-full' />}

      <div className='grow w-fit'>
        <h2 className='text-xl font-semibold mb-1'>{name}</h2>
        <p>{snippet}</p>
      </div>
    </ConditionAssessmentLink>
  )
}